/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ApiStatus } from '../../interfaces/ApiStatus';
import fieldsApi, { ScannedFieldResponseData } from '../../api/fieldsApi.service';
import { setFullPdfPresignedURL, setScanPresignedURL, setTotalPageNos, updateAlreadySubmittedScan, updateLastUpdatedDateTime, updateScanInList } from '../scans/scansReducer';
import { ScanByIdApiResponse, ScanFullFormResponse } from '../../api/scansApi.service';
import { Record } from '../../interfaces/Record';

/**
 * An interface with keys to fetch fields of specific scan 
 *
 * @interface ScanCallingDetails
 * @typedef {ScanCallingDetails}
 */
interface ScanCallingDetails {
    formType: string;
    id: string;
}

/**
 * An interface with keys to fetch fields of specific template
 *
 * @interface FetchFieldsByFormId
 * @typedef {FetchFieldsByFormId}
 */
interface FetchFieldsByFormId {
    scanType: string;
    templateName: string;
}

/**
 *  An interface with keys used for state of fields store
 *
 * @interface FieldState
 * @typedef {FieldState}
 */
interface FieldState {
    list: Array<ScannedFieldResponseData>,
    status: ApiStatus,
    error: string | null,
    revision: string,
    fullFormLoading?: ApiStatus
}

/**
 * An initial state of fields store
 *
 * @type {FieldState}
 */
const initialState: FieldState = {
    list: [],
    status: 'idle',
    error: null,
    revision: uuidv4(),
    fullFormLoading: 'idle'
}

/**
 * An async thunk function to request fields from specific scan.
 * Using thunkAPI to dispatch actions from other stores
 *
 * @param {ScanCallingDetails} scanDetails Form type and id of scan
 * @type {*}
 */
export const fetchFieldsByScanId = createAsyncThunk('fields/fetchFieldsByScanId', async (scanDetails: ScanCallingDetails, thunkAPI) => {
    const response: ScanByIdApiResponse = await fieldsApi.getByScanId(scanDetails.formType, scanDetails.id);
    if(response.message && response.message.length > 0) {
        thunkAPI.dispatch(setScanPresignedURL(undefined));
        throw new Error(response.message);
    }
    thunkAPI.dispatch(setScanPresignedURL(response.imagePresignedURL as string | ''));
    thunkAPI.dispatch(setTotalPageNos(response.totalPage as number | 1));
    thunkAPI.dispatch(updateLastUpdatedDateTime({ id: scanDetails.id, lastUpdatedDateTimeSec: response.lastUpdatedDateTimeSec}))
    if(response.wasAlreadySubmitted) {
        thunkAPI.dispatch(updateAlreadySubmittedScan({ id: scanDetails.id }))
    }

    return response;
})

export const getUpdatedScan = createAsyncThunk('fields/fetchFieldsByScanId', async (scanDetails: { formType: string; id: string; formStatus: string }, thunkAPI) => {
    const response: ScanByIdApiResponse = await fieldsApi.getByScanId(scanDetails.formType, scanDetails.id);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    thunkAPI.dispatch(updateScanInList({ ...response, id: scanDetails.id, selectedFormStatuses: scanDetails.formStatus }));

    return response;
})

/**
 * An async thunk function to request full pdf for a specific scan.
 * Using thunkAPI to dispatch actions from other stores
 *
 * @param {ScanCallingDetails} scanDetails Form type and id of scan
 * @type {*}
 */
export const fetchFullFormByScanId = createAsyncThunk('fields/fetchFullFormByScanId', async (scanDetails: ScanCallingDetails, thunkAPI) => {
    const response: ScanFullFormResponse = await fieldsApi.getFullFormByScanId(scanDetails.formType, scanDetails.id);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    thunkAPI.dispatch(setFullPdfPresignedURL(`${response.pdfPresignedURL}#toolbar=0&navpanes=0&scrollbar=0` as string | ''));

    return response;
})

/**
 * An async thunk function to request fields of specific template
 * 
 * @param {FetchFieldsByFormId} params Scan type and template name
 * @type {*}
 */
export const fetchFieldsByFormId = createAsyncThunk('fields/fetchFieldsByFormId', async (params: FetchFieldsByFormId) => {
    const { scanType, templateName } = params;
    const response = await fieldsApi.getByFormId(scanType, templateName);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    return response;
})

/**
 * A slice in fields store with reducers
 *
 * @type {*}
 */
export const fields = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        resetFields: () => ({
            list: [],
            status: 'idle' as ApiStatus,
            error: null,
            revision: uuidv4()
        }),
        resetFieldsError: (state) => {
            state.error = null;
            return state;
        },
        updateAcceptedValueOnField: (state, action) => {
            /**
             * Update value in field
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.record.id);
            if(foundIndex > -1) {
                state.list[foundIndex].acceptedValue = action.payload.text;
                state.revision = uuidv4();
            }
            return state;
        },
        updateLockOnField: (state, action) => {
            /**
             * Toggle a lock on field
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].locked = !state.list[foundIndex].locked;
                state.list[foundIndex].message = '';
                state.revision = uuidv4();
            }
            return state;
        },
        lockField: (state, action) => {
            /**
             * Lock a field
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].locked = true;
                state.list[foundIndex].message = '';
                state.revision = uuidv4();
            }
            return state;
        },
        setFieldInvalid: (state, action) => {
            /**
             * Mark a field as invalid with value
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].isValid = false;
                state.revision = uuidv4();
            }
            return state;
        },
        setFieldValid: (state, action) => {
            /**
             * Mark a field as valid with value
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].isValid = true;
                state.revision = uuidv4();
            }
            return state;
        },
        toggleFieldUndefined: (state, action) => {
            /**
             * Mark a field as unreadable with value
             */
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].isUnreadable = !state.list[foundIndex].isUnreadable;
                state.list[foundIndex].acceptedValue = '';
                // state.list[foundIndex].message = 'Set as unreadable by reviewer';
                state.revision = uuidv4();
            }
            return state;
        },
        highlightField: (state, action) => {
            /**
             * Highlight selected field in bounding box overlay
             */
            state.list = state.list.map(field => {
                field.boundingBox.isHighlighted = false;
                return field;
            });
            const foundIndex = state.list.findIndex(field => field.id === action.payload.id);
            if(foundIndex > -1) {
                state.list[foundIndex].boundingBox.isHighlighted = true;
                state.revision = uuidv4();
            }
            return state;
        },
        setErrorFields: (state, action) => {
            /**
             * Highlight Error fields
             */
            state.list = state.list.map(field => {
                const matchedField = action.payload?.find((i: any) => i.field_name === field.key);
                if (matchedField) {
                    field.locked = false;
                    field.message = matchedField.message;
                    return field;
                }
                return field;
              });
            state.revision = uuidv4();
            return state;
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchFieldsByScanId.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        builder.addCase(fetchFieldsByFormId.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchFieldsByScanId.fulfilled, (state, action) => {
            state.status = 'completed';
            const alteredResults = action.payload.results as ScannedFieldResponseData[];
            state.list = alteredResults
                .map((obj: ScannedFieldResponseData) => {
                    const newId = uuidv4();
                    return {
                        ...obj, 
                        id: newId,
                        isValid: true,
                        isUnreadable: obj.isUnreadable !== null ? obj.isUnreadable : false,
                        // Conversion of confidence and confidenceThreshold from string to integer is implemented for the sake of old records, which have confidence values as string
                        locked: obj.locked !== null ? obj.locked : obj.message.length === 0 && ((parseInt(obj.confidence as unknown as string, 10) >= parseInt((obj.confidenceThreshold || 0) as unknown as string, 10) || (obj.acceptedValue.length === 0 && obj.isRequired === false))),
                        boundingBox: {
                            ...obj.boundingBox,
                            id: newId,
                            isHighlighted: false
                        } as unknown as Record
                    }
                })
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchFieldsByFormId.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list =  action.payload.results as ScannedFieldResponseData[];
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchFieldsByScanId.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchFieldsByFormId.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchFullFormByScanId.pending, (state) => {
            state.fullFormLoading = 'loading';
            return state;
        })
        .addCase(fetchFullFormByScanId.fulfilled, (state) => {
            state.fullFormLoading = 'completed';
            state.revision = uuidv4();
            return state;
        })
        .addCase(fetchFullFormByScanId.rejected, (state, action) => {
            state.fullFormLoading = 'failed';
            state.error = action.error.message as string;
            state.revision = uuidv4();
            return state;
        })
    }
})

export const { resetFieldsError, setFieldValid, setFieldInvalid, updateLockOnField, updateAcceptedValueOnField, lockField, resetFields, toggleFieldUndefined, highlightField, setErrorFields } = fields.actions;

export default fields.reducer
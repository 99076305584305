/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Input, InputRef, Select, Space, Switch, Table, Tooltip, Typography, notification } from "antd"
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LockFilled, LockOutlined, UnlockFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { InferProps } from "prop-types";
import LOCALIZATION from '../../../localization';
import { ScannedFieldResponseData } from "../../../api/fieldsApi.service";
import colors from "../../../colors";
import { AppDispatch, RootState } from "../../../store/store";
import { setFieldInvalid, setFieldValid, toggleFieldUndefined, updateAcceptedValueOnField, updateLockOnField, lockField, highlightField } from "../../../store/fields/fieldsReducer";
import UnsavedChangesContext from "../../../context/UnsavedChanges";
import "./FieldsTable.scss";
import { selectConfigTypeByName } from "../../../store/configTypes/configTypesReducer";

interface FieldsTableProps {
  /**
   * A reference to method in parent component that handles click on row in fields table
   */
  onFieldRowClick: any;
  /**
   * A value to what height should fields table stretch to; used to set "scroll" property of Antd's Table component
   */
  stretchHeightTo: string;
  /** 
   * Prevents any changes if hardLock is set; reference to hardLock state
  */
  hardLock: boolean;
  /**
   * A reference to setHardLock state's method
   */
  setHardLock: (value: boolean) => void;
  /** 
   * Disable any control components if scan was submitted
  */
  scanWasSubmitted: boolean;
}

/**
 * Component with styled LockFilled icon to mark locked fields
 */
const StyledLockFilled = styled(LockFilled)`
  color: ${colors.lockedLockColor};
  font-size: 1.3em;
`
/**
 * Component with styled LockOutlined icon to mark already submitted and locked fields
 */
const StyledLockOutlined = styled(LockOutlined)`
  color: ${colors.lockedLockColor};
  font-size: 1.3em;
`

/**
 * Component with styled UnlockFilled icon to mark unlocked fields
 */
const StyledUnlockFilled = styled(UnlockFilled)`
  color: var(--color-neutral-04);
  font-size: 1.3em;
`

/**
 * Component with styled UnlockFilled icon to mark fields with failed validation
 */
const StyledUnlockFilledwithError = styled(UnlockFilled)`
  color: ${colors.unlockedLockColorWithError};
  font-size: 1.3em;
`

/**
 * FieldsTabke is FindScans's child component with Antd components to display a table with scanned fields, values and control components.
 * 
 * Relies on fields store, which populates table with scanned fields and configTypes store for validation checks.
 * 
 * This component is using props to exchange data and actions with parent component.
 * 
 * @type {React.FC<InferProps<FieldsTableProps>>}
 * @returns {React.ReactElement} Fields table
 */
function FieldsTable({ onFieldRowClick, stretchHeightTo = '40vh', hardLock, setHardLock, scanWasSubmitted }: FieldsTableProps) {
    const [api, contextHolder] = notification.useNotification();
    const [errorMessage, setErrorMessage] = useState<string>(); 
    const [fieldRow, setFieldRow] = useState<ScannedFieldResponseData>();
    const [fields, setFields] = useState<ScannedFieldResponseData[]>([]);
    
    const dispatch = useDispatch<AppDispatch>();

    const fieldsStoreList = useSelector((state: RootState) => state.fields.list);
    const fieldsStoreStatus = useSelector((state: RootState) => state.fields.status);
    const fieldsStoreRevision = useSelector((state: RootState) => state.fields.revision);

    const configTypesStore = useSelector((state: RootState) => state.configTypes);
  
    const scansStoreStatus = useSelector((state: RootState) => state.scans.status);

    const valueInputsRef = useRef<any>({});

    const { markUnsavedChanges } = useContext(UnsavedChangesContext)

    const tableRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);

    const [isTableScrolledToBottom, setTableScrolledToBottom] = useState<boolean>(false);


    /**
     * Validate new value of accepted value
     * 
     * @public
     * @param text New value
     * @param record Record/field
     * @returns boolean
     */
    const acceptedValueChangeValidator = (text: string | number, record: ScannedFieldResponseData) => {
      if(record.isUnreadable) {
        return true;
      }

      if(record.requiredType) {
        if(text === null) {
          text = "";
          if(!record.isRequired) {
            return true;
          }
        }

        const re = new RegExp(selectConfigTypeByName(configTypesStore, record.requiredType)?.regex_expression as string, '');
        return re.test(text as unknown as string)  
      } 
      return true;
    } 

    /**
     * Validate value of accepted value if value is chosen from dropdown
     * @type {Function}
     * @param scannedField Record/field
     * @returns string
     */
    const boolAcceptedValue = (scannedField: ScannedFieldResponseData): string => {
      const fieldsType = selectConfigTypeByName(configTypesStore, scannedField.requiredType);
      if(fieldsType && fieldsType.return_values) {
        try {
              const re = new RegExp(fieldsType?.regex_expression as string, '');
              const testResult = re.test(scannedField.scannedValue as string);
              if(fieldsType.return_values) {
                if(testResult) {
                  dispatch(updateAcceptedValueOnField({text: fieldsType?.return_values[1], scannedField}))
                  return fieldsType?.return_values[1];
                } 

                dispatch(updateAcceptedValueOnField({text: fieldsType?.return_values[0], scannedField}))
                return fieldsType?.return_values[0];    
              }

              return null as unknown as string;
          } catch(error) {
              console.warn(error);
              return null as unknown as string;
          }
      }

      return null as unknown as string;
    }

    /**
     * Validate value of accepted value if value is string
     * @param scannedField Record/field
     * @returns string
     */
    const textBoolAcceptedValue = (scannedField: ScannedFieldResponseData): string => {
      const fieldsType = selectConfigTypeByName(configTypesStore, scannedField.requiredType);
      if(fieldsType && fieldsType.return_values) {
        const returnValueIndex = fieldsType.return_values?.indexOf(scannedField.acceptedValue) as number;
        if(returnValueIndex >= -1) {
          if(fieldsType.return_values_display) {
            return fieldsType.return_values_display[returnValueIndex];
          }
        }
      }

      return scannedField.acceptedValue;      
    }

    /**
     * Will update accepted value of scanned field
     * @param event ChangeEvent
     * @param record Scanned field/record
     */
    const handleScannedFieldUpdate = (event: React.ChangeEvent<HTMLInputElement>, record: ScannedFieldResponseData) => {
      const foundIndex = fields.findIndex(field => field.id === record.id);
      if(foundIndex > -1) {
        fields[foundIndex].acceptedValue = event.target.value;
      }

      markUnsavedChanges();
      dispatch(updateAcceptedValueOnField({text: event.target.value, record}))
    };

    /**
     * Handle update value of scanned field with dropdown
     * @param value Updated value
     * @param record Scanned field/record
     */
    const handleScannedFieldUpdateFromSelect = (value: string, record: ScannedFieldResponseData) => {
      const foundIndex = fields.findIndex(field => field.id === record.id);
      if(foundIndex > -1) {
        fields[foundIndex].acceptedValue = value;
      }

      markUnsavedChanges();
      dispatch(updateAcceptedValueOnField({text: value, record}))
    };
  
    /**
     * Handle update value of scanned field with toggle
     * @param record Scanned field/record
     */
    const handleScannedFieldLockToggle = (record: ScannedFieldResponseData) => {
      if(!record.locked) {
        if(acceptedValueChangeValidator(record.acceptedValue, record)) {
          dispatch(setFieldValid(record));
          dispatch(updateLockOnField(record));
        } else {
          setErrorMessage(() => `${LOCALIZATION.FORM_FIELD_VALIDATION_ERROR_SHORT_MESSAGE} ${selectConfigTypeByName(configTypesStore, record.requiredType)?.display_name}.`)
          dispatch(setFieldInvalid(record));
          api.destroy();
          api.error({
            message: LOCALIZATION.FORM_FIELD_VALIDATION_ERROR_TITLE,
            description:
              `${LOCALIZATION.FORM_FIELD_VALIDATION_ERROR_MESSAGE} ${selectConfigTypeByName(configTypesStore, record.requiredType)?.display_name}.`,
            placement: 'bottomLeft'
          });
        }  
      } else {
        dispatch(updateLockOnField(record));
      }
      markUnsavedChanges();
    };

    /**
     * Based on message from Textract decide class name of scanned field
     * @param record Scanned field/record
     * @returns String
     */
    const decideRowClassName = (record: ScannedFieldResponseData) => {
      let returnClassNames = '';
      if(record) {
        if(record.message.length > 0) {
          returnClassNames = `has-issues ${returnClassNames}`;
        }

        if(record.boundingBox.isHighlighted) {
          returnClassNames = `selected-row ${returnClassNames}`;
        }
      }

      return returnClassNames;
    }

    /**
     * Render accepted value input component with proper properties according to state of app
     * @param record Scanned field/record
     * @returns React.Component
     */
    const acceptedValueInputComponent = (record: ScannedFieldResponseData) => {
      if(!hardLock) {
        if(!record.locked && !record.isUnreadable) {
          if(record.isValid) {
            return <>{contextHolder}<Input 
            size="small" 
            value={record.acceptedValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleScannedFieldUpdate(event, record)}
            ref={(element) => {
              valueInputsRef.current[record.id] = element as InputRef
            }}
            /></>
          } 
          return <>{contextHolder}<Tooltip title={`${LOCALIZATION.FORM_FIELD_VALIDATION_ERROR_MESSAGE} ${selectConfigTypeByName(configTypesStore, record.requiredType)?.display_name}.`}><Input 
          status="error"
          size="small" 
          value={record.acceptedValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleScannedFieldUpdate(event, record)}
          ref={(element) => {
            valueInputsRef.current[record.id] = element as InputRef
          }}
          /></Tooltip></>
        }
      }
      return (record.acceptedValue || LOCALIZATION.UNDEFINED_VALUE);
    }

    /**
     * Render accepted value select component with proper properties according to state of app
     * @param record Scanned field/record
     * @param options List of label-value pairs as select/dropdown options
     * @returns React.Component
     */
    const accepterValueSelectComponent = (record: ScannedFieldResponseData, options: Array<{label: string, value: string}>) => {
      if(!hardLock) {
        if(!record.locked && !record.isUnreadable) {
          if(record.isValid) {
            return <>{contextHolder}
              <Select
                defaultValue={record.acceptedValue}
                size="small"
                options={options}
                onChange={(value: string) => handleScannedFieldUpdateFromSelect(value, record)}
                dropdownMatchSelectWidth
                style={{width: '100px'}}
                virtual={false}
              />
            </>
          }
          return <>{contextHolder}
            <Tooltip title={errorMessage}>
              <Select
                defaultValue={record.acceptedValue}
                status="error"
                size="small"
                options={options}
                onChange={(value: string) => handleScannedFieldUpdateFromSelect(value, record)}
                dropdownMatchSelectWidth
                style={{width: '100px'}}
                virtual={false}
              />
            </Tooltip>
          </>
        }
      }
      return (textBoolAcceptedValue(record) || LOCALIZATION.UNDEFINED_VALUE)
    }
    /**
     * Handler responding to scoll event on table
     */
    const onScroll = () => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setTableScrolledToBottom(() => true);
        }
        else {
          setTableScrolledToBottom(() => false);
        }
      }
    }
    
    const isIdentifiedOrNotIdentified = (record: ScannedFieldResponseData) => record.requiredType === LOCALIZATION.IDENTIFIED_NOTIDENTIFIED_REQUIRED_TYPE

    const setLockItem = (record: ScannedFieldResponseData)=>{
      if(!hardLock) {
        handleScannedFieldLockToggle(record);
        markUnsavedChanges();
      } 
    }
    
    const useKeyDown = (callback: () => void, keys: any[]) => {
      const onKeyDown = (event: any) => {
        const wasAnyKeyPressedPlusCtrl = keys.some((key) => event.key === key && event.ctrlKey);
        const wasAnyKeyPressedPlusCtrlShift = keys.some((key) => event.key === key && event.ctrlKey && event.shiftKey);
        if (wasAnyKeyPressedPlusCtrl && !wasAnyKeyPressedPlusCtrlShift) {
          event.preventDefault();
          callback();
        }
      };
      useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
          document.removeEventListener('keydown', onKeyDown);
        };
      }, [onKeyDown]);
    };
    useKeyDown(() => {
      setLockItem(fieldRow || null as unknown as ScannedFieldResponseData);
    }, ["d", "D"]);

    
    /**
     * Watch changes in fields store by watching revisions
     * If change, populated fields state
     */
    useEffect(() => {
      setFields(() => fieldsStoreList.map(obj => ({
          ...obj,
          acceptedValue: obj.acceptedValue === null || obj.acceptedValue.length === 0 ? boolAcceptedValue(obj) : obj.acceptedValue
        }))
      )

      tableRef.current = document.querySelector('#FindScans_FieldsTable_Table_table .ant-table-body') || null as unknown as HTMLDivElement;
      if(tableRef.current){
        tableRef.current.addEventListener('scroll', onScroll, { passive: true });
      }
    }, [fieldsStoreRevision]);

    useEffect(() => {
      if(tableRef.current){
        tableRef.current.addEventListener('scroll', onScroll, { passive: true });
      }
    }, [])

    return (
      <Table
        id="FindScans_FieldsTable_Table_table"
        dataSource={fields}
        ref={tableRef}
        loading={fieldsStoreStatus === 'loading' || scansStoreStatus === 'loading'}
        className={`FieldsTable-table secondary-table ${fields.length === 0 || isTableScrolledToBottom ? 'no-shadow' : ''}`}
        pagination={false}
        columns={[{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_KEY,
          dataIndex: 'key',
          key: 'key',
          width: '200px',
          render: (text: string, record: ScannedFieldResponseData) => {
            if(record.isRequired) {
              return <>
                <Typography.Text className="key-asterix">*</Typography.Text>
                <Typography.Text style={{color: 'inherit'}}>{text}</Typography.Text>
              </>;
            }
            return <Typography.Text>{text}</Typography.Text>;
          }
        },{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_SCANNED_VALUE,
          dataIndex: 'scannedValue',
          key: 'scannedValue',
          width: '160px',
          className: 'font-verdana'
        },{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_ACCEPTED_VALUE,
          dataIndex: 'acceptedValue',
          key: 'acceptedValue',
          render: (text: string, record: ScannedFieldResponseData) => {
            const typeObject = selectConfigTypeByName(configTypesStore, record.requiredType);
            if(typeObject && (typeObject.suggest_dropdown || typeObject.return_values)) {
              return accepterValueSelectComponent(record, typeObject?.dropdown_options as Array<{label: string, value: string}>);
            }
            return acceptedValueInputComponent(record);
          },
          width: '160px',
          className: 'font-verdana'
        },{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_UNREADABLE,
          dataIndex: 'isUnreadable',
          key: 'isUnreadable',
          render: (isUnreadable: boolean, record: ScannedFieldResponseData) => !isIdentifiedOrNotIdentified(record) && <Switch
            style={record.locked && !record.isUnreadable ? { visibility: 'hidden' } : { visibility: 'visible' }}
            disabled={record.locked}
            checked={record.isUnreadable}
            onChange={(checked: boolean) => {
              const newFields = fields.map((field: ScannedFieldResponseData) => {
                if (field.id === record.id) {
                  dispatch(toggleFieldUndefined(record));
                }
                return field;
              });
              setFields(() => newFields);
              markUnsavedChanges();
            } } />,
            width: '140px'
        },{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_CONFIDENCE,
          dataIndex: 'confidence',
          key: 'confidence',
          width: '140px'
        },{
          title: LOCALIZATION.FORM_FIELDS_TABLE__HEADER_LOCK,
          dataIndex: 'locked',
          key: 'locked',
          render: (isLocked: boolean, record: ScannedFieldResponseData) => {
            if(hardLock) {
              return <>{contextHolder}<Tooltip title={LOCALIZATION.FORM_FIELDS_ALREADY_SUBMITTED_FORM}>
                <Space className="lock-icon-wrapper"><span className="icon-lock-hardLocked" /></Space></Tooltip></>
            }
            if(isLocked) {
              return <Space className="lock-icon-wrapper"><span className="icon-lock-locked" /></Space>
            } 
            if(!record.isValid) {
              return <>{contextHolder}<Tooltip title={`${LOCALIZATION.FORM_FIELD_VALIDATION_ERROR_MESSAGE} ${selectConfigTypeByName(configTypesStore, record.requiredType)?.display_name}.`}>
                <Space className="lock-icon-wrapper"><span className="icon-lock-error-unlocked" /></Space></Tooltip></>
            }
            return <Space className="lock-icon-wrapper"><span className="icon-lock-unlocked" /></Space>
          },
          onCell: (record) => ({
            onClick: () => {
              setLockItem(record)
            }
          }),
          width: '50px',
          fixed: 'right'
        }]}
        rowClassName={(record) => decideRowClassName(record)}
        rowKey={(record) => (record && record.id)}
        scroll={{
          scrollToFirstRowOnChange: true,
          y: stretchHeightTo
        }}
        size='small'
        onRow={(record) => ({
          onClick: () => {
            setFieldRow(()=>record)
            dispatch(highlightField(record));
            onFieldRowClick(record);
          },
          onKeyUp: () => {
            setFieldRow(()=>record)
          },
        })}
      />
    )
}

/**
 * 
 */
export default FieldsTable;

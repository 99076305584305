/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import { createAsyncThunk, createSlice, PayloadAction  } from '@reduxjs/toolkit';
import { ApiStatus } from '../../interfaces/ApiStatus';
import { FlowTask, FlowTaskByNameParams, flowTasksApi } from '../../api/flowTasksApi.service';


/**
 * An interface describing state of flowTasks store
 *
 * @interface FlowTaskState
 * @typedef {FlowTaskState}
 */
interface FlowTaskState {
    list: Array<FlowTask>,
    status: ApiStatus,
    error: string | null
}

/**
 * An initial state of flowTasks store
 *
 * @type {FlowTaskState}
 */
const initialState: FlowTaskState = {
    list: [],
    status: 'idle',
    error: null
}

/**
 * An async thunk function to fetch flow tasks by form name and form type
 *
 * @type {*}
 */
export const fetchFlowTaskByName = createAsyncThunk('flowtasks/byName', async (params: FlowTaskByNameParams) => {
    const response = await flowTasksApi.getById(params.formName, params.formType);
    if(response.message && response.message.length > 0) {
        throw new Error(response.message);
    }
    return response;
})

/**
 * A slice in flowTasks store with reducers
 *
 * @type {*}
 */
export const flowTasks = createSlice({
    name: 'flowTasks',
    initialState,
    reducers: {
        updateFlowTask: (state, action: PayloadAction<FlowTask>) => {
            state.list.push(action.payload);
            return state;
        }},
    extraReducers(builder) {
        builder.addCase(fetchFlowTaskByName.pending, (state) => {
            state.status = 'loading';
            return state;
        })
        .addCase(fetchFlowTaskByName.rejected, (state, action) => {
            state.status = 'failed';
            state.list = [];
            state.error = action.error.message as string;
            return state;
        })
        .addCase(fetchFlowTaskByName.fulfilled, (state, action) => {
            state.status = 'completed';
            state.list = action.payload.results as Array<FlowTask>;
            return state;
        })
    }
})

export const { updateFlowTask } = flowTasks.actions;

export default flowTasks.reducer